// @import "../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
// @import "../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
// @import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

$color-1: #66A24A;
$color-2: #403d39;
$color-3: #263B1A;
$color-4: #84A98C;
$color-5: #403d39;
$color-6: #000000; // black;
$color-7: #323232;
$color-8: #DAE4E6;
$color-9: #DEDEDE; // light-grey
$color-10: #FFFFFF; // white

/* You can add global styles to this file, and also import other style files */

/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/* MARKETING CONTENT
-------------------------------------------------- */
.marketing {
  padding-top: 150px;
  padding-bottom: 150px;
}

.marketing .col-lg-4 {
  text-align: center;
  padding: 40px;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}

/* APP COMPONENT CONTENT
-------------------------------------------------- */
.custom-icon {
    position: relative;
  }
  
.small-icon-top {
color: white;
font-size: 15px;
margin-right: 5px;
position: absolute;
left: 24px;
top: 14px;
}

.notifications {

// .dropdown-toggle::after {
//   visibility: hidden;
// }

.notification {
    padding: 20px;
    font-size: 12px;
    border-bottom: 1px solid #dedede;
}

.dropdown-menu {
    width: 300px;
    padding: 0px;
    left: -250px
}
}

.nav-route {
  text-decoration: none;
  color: #000000;
  margin: 0px 8px ;
}


/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .featurette-heading {
    font-size: 50px;
  }
}

.color-1 {
  color: $color-1;
}
.color-2 {
  color: $color-2;
}
.color-3 {
  color: $color-3;
}
.color-4 {
  color: $color-4;
}
.color-5 {
  color: $color-5;
}
.color-6 {
  color: $color-6;
}
.color-7 {
  color: $color-7;
}

.bg-color-1 {
  background: $color-1;
}
.bg-color-2 {
  background: $color-2;
}
.bg-color-3 {
  background: $color-3;
}
.bg-color-4 {
  background: $color-4;
}
.bg-color-5 {
  background: $color-5;
}
.bg-color-6 {
  background: $color-6;
}
.bg-color-7 {
  background: $color-7;
}

.btn-primary {
  background: $color-1 !important;
  border-color: $color-1 !important;
}

.btn-secondary {
  background: $color-5 !important;
  border-color: $color-5 !important;
  color: $color-10 !important;
  font-weight: bold !important;
}

.btn-link,
.nav-link {
  color: $color-6 !important;
  text-decoration: none !important;
}

.gradient {
  background-image: linear-gradient(to bottom right, $color-1, $color-3) !important;
}

.absolute-header {
  position: absolute;
  width: 100%;
  z-index: 99;
}

.regular-header {
  background-color: $color-8;
}

.carousel-item {
  height: 100vh !important;
}

.icon-xl {
  font-size: 250px;
  margin-bottom: 20px;
  opacity: 0.8;
}

.text-muted-2 {
  color: $color-5;
}

h1 {
  color: $color-2;
}

.large-icon-back {
  font-size: 35px;
  margin-right: 9px;
}

.carousel-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  padding-left: 200px;
  padding-right: 200px;
  color: $color-10;
  max-width: 1320px;
  margin: auto;

  h1 {
    color: $color-9;
  }

  p {
    color: $color-10;
  }

  @media (max-width: 768px) {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.tile {
  text-align: center;
  padding: 50px 30px;
  background: $color-9;
  margin-bottom: 40px;

  .material-icons {
    font-size: 140px;
    color: $color-2;
  }

  h2.tile-title {
    color: $color-2;
    font-weight: 400;
  }

  p.tile-description {
    color: $color-6;
    margin-top: 20px;
  }
}

.container {
  @media (max-width: 768px) {
    padding: 40px !important;
  }
}

.footer {
  .links {
    float: right;
    text-align: right;
  }

  .btn-link,
  .nav-link {
    color: $color-4 !important;
    text-decoration: none !important;
  }
}

.padded-container {
  padding-top: 40px;
  padding-bottom: 60px;
}

.mat-ripple-element {
  width: 0px !important;
}

.mat-tab-label {
  opacity: 1 !important;
  border: 1px solid $color-9 !important;
  border-radius: 5px !important;
}

.mat-tab-label-active {
  background: $color-1 !important;
  color: $color-10 !important;
}

.passed {
  background: #6BB3D6;
}

.failed {
  background: #F2927F;
}

.table {
  color: #686868;
  font-size: 12px;
}

.table td {
  position: relative;
}

.progress {
  background-color: #FFFFFF;
  .progress-bar {
    background-color: #6BB3D6;
  }
}

.failed-background {
  background-color: #F2927F !important;
}

.passed-background {
  background-color: #6BB3D6 !important;
}

.info-text {
  color: #FFFF00;
}

.low-text {
  color: #FFA500;
}

.medium-text {
  color: #FFA07A;
}

.high-text {
  color: #B22222;
}

.critical-text {
  color: #800000;
}

.progress-fraction {
  position: absolute;
  width: 80%;
  font-weight: 600;
}

.progress-table {
  max-width: 85px;
  width: auto !important;
  height: 28px !important;
  margin-top: 7px;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

.toast {
  background: white !important;
  border-radius: 10px !important;
  padding: 8px;
  margin-bottom: 5px;

  .toast-header {
    border-radius: 50px !important;
    border: 0;
    padding-bottom: 0px;
  }

  .toast-body {
    padding-top: 5px;
  }
}

.multiselect-dropdown .dropdown-btn {
  padding: 1rem .75rem !important;
}

.is-invalid .multiselect-dropdown .dropdown-btn {
  border-color: var(--bs-form-invalid-border-color);
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.nav-item.dropdown {
  ::marker {
    display: none;
  }
}
