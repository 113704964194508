.container--flex{
    display:-webkit-box;
    display:-moz-box;
    display:box;
    display:-webkit-flex;
    display:-moz-flex;
    display:-ms-flexbox;
    display:flex
}

.comparison-table{
    min-width:43.75rem;
    table-layout:fixed;
    line-height:130%;
    border-collapse:collapse
}

.comparison-table col{
    width:12.5rem
}

.comparison-table th{
    padding-top:1.0rem;
    padding-bottom:1.0rem;
    border-top:1px solid #f5f6f8;
    border-bottom:1px solid #f5f6f8;
    background:#fff;
    font-weight:400;
    text-align:left
}

.comparison-table p{
    margin-bottom:0;
    font-size:0.8rem
}

.comparison-table .green{
    color:#0b2163
}

.comparison-table h5{
    margin-top:1rem
}

.comparison-table .comparison-table-heading{
    font-weight:700;
    padding-top:2.0rem;
    padding-bottom:2.0rem
}

.comparison-table .comparison-table-plans{
    padding:1.0rem;
    text-align:center
}

.comparison-table thead th:nth-child(2){
    border-top-left-radius:10px;
    border-top-right-radius:10px
}

.comparison-table td{
    padding:1.0rem;
    min-width:12.5rem;
    text-align:center;
    border:1px solid #f5f6f8
}

// .comparison-table td:last-child{
//     border-right:0
// }

// .comparison-table td:nth-child(3n){
//     border-right:2px solid #0b2163
// }

.comparison-table .featured-plan{
    border-top:2px solid #0b2163;
    border-right:2px solid #0b2163;
    border-left:2px solid #0b2163
}

.comparison-table .featured-plan-cell{
    border:2px solid #0b2163;
    border-width:0 2px 0 2px;
    border-bottom:1px solid #f5f6f8
}

.comparison-table .featured-plan-cell.last{
    border-bottom:2px solid #0b2163
}

.comparison-table .featured-plan-cell svg{
    fill:#0b2163
}

.comparison-table svg{
    width:1.25rem
} 
.comparison-table svg, .comparison-table img{
    height:1.25rem;
    fill:#363f44;
    margin:auto
}

.comparison-table .btn-small{
    margin-top:1.0rem
}

.comparison-table tfoot td{
    min-width:100%;
    text-align:left;
    border:none;
    padding:2.0rem 0 0
}

.comparison-table tfoot td span{
    font-size:1.0rem
}

.comparison-table .table-bg--gray{
    background-color:#f5f6f8
}

.comparison-table-header th{
    border-top:0;
    border-bottom:1px solid #f5f6f8;
    border-right:1px solid #f5f6f8
}

// .comparison-table-header th:last-child{
//     border-right:0
// }

.comparison-table-header th:nth-child(4n), .plan-header{
    border-right:2px solid #0b2163
}

.comparison-table-header img{
    max-width:6.0rem;
    margin:0 auto 1.0rem
}

.hidden{
    display: none;
}