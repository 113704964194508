.roundedimg {
    overflow: hidden;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    width: 110px;
    height: 110px;
}
  
.blockimg {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
  
